var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-component contributors-page column" },
    [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("label", { attrs: { for: "text" } }, [
            _vm._v(_vm._s(_vm.$t("contributorsPage")) + ":")
          ]),
          _c("ckeditor", {
            attrs: {
              editor: _vm.editor,
              "tag-name": "textarea",
              config: _vm.editorConfig
            },
            model: {
              value: _vm.contributorsPage,
              callback: function($$v) {
                _vm.contributorsPage = $$v
              },
              expression: "contributorsPage"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }