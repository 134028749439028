<template>
    <div class="contributors-page-builder builder flex-container">
        <div class="column">
            <contributors-page-card v-if="component" :component="component"/>
        </div>
        <properties-panel :save-component="saveComponent"/>
    </div>
</template>
<script>
import BuilderMixin from '@/views/BackOffice/BuilderMixin.js'
import ContributorsPageCard from '@/components/BackOffice/ContributorsPageBuilder/ContributorsPageCard.vue'
export default {
    name: 'ContributorsPageBuilder',
    mixins:[BuilderMixin], 
    components:{
        ContributorsPageCard
    }, 
    computed:{
        component(){
            return this.$store.state.contributorsPageComponent
        },
    },
    created(){
        this.$store.commit('updateSwitchButtonDisplayStatus', false)
        this.$store.commit('updateContentSectionState', true)
        this.$store.dispatch('loadContributorsPageComponent')
    },
    methods:{
        saveComponent(){
            this.$store.dispatch('updateContributorsPageComponent', this.component)
        }
    },
}
</script>
<style lang="less" scoped>
.contributors-page-builder{
    margin-top: 104px;
    height: calc(100vh - 104px);
}
</style>